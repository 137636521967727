import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { useSiteMetadata } from '../hooks'
import Hero from '../components/Hero'
import { Helmet } from 'react-helmet/es/Helmet'

const PageTemplate = ({ data }) => {
  const { title: siteTitle } = useSiteMetadata()
  const { html: pageBody, frontmatter } = data.markdownRemark
  const siteDescription = pageBody.excerpt

  return (
    <Layout
      title={`${frontmatter.title} | ${siteTitle}`}
      description={siteDescription}
    >
      <Helmet>
        {frontmatter.title === 'Tickets' && (
          <script async defer src="https://www.trybooking.com/widget.js"/>
        )}
      </Helmet>
      <Hero className={'hero is-transparent is-medium'} isIndex={false} title={frontmatter.title}/>

      <div className={'columns has-padding-top-30 has-padding-bottom-30 has-padding-20-mobile'}>
        <div className={'column is-offset-one-quarter is-half'}>
          <article dangerouslySetInnerHTML={{ __html: pageBody }} />
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
